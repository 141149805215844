//
//
//
//
// This file is saved for reference, but if you need to make changes to the
// product-detail page, please use the brand-styles/templates/product-detail file
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { css } from 'styled-components';
import background from '../../images/bg-pd-page.png';

export default css`
  .domol {
    padding: 60px 15px;
  }
  #cooking {
    background-image: url(${background});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: none;
  }
`;
