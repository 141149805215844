//
//
//
//
// This file is saved for reference, but if you need to make changes to the
// product-detail page, please use the templates/product-detail file
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import React, { Component } from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/pages/product-detail.styles';
import { breakpoints } from '../style-utilities/variables';
import { colorConflict } from '../utilities/colorUtils';
// import components
import ProductDetailHero from '../components/ProductDetail/ProductDetailHero';
import PdpSection from '../components/ProductDetail/PdpSection';
import FloatingProduct from '../components/ProductDetail/FloatingProduct';
import FloatingSubNav from '../components/ProductDetail/FloatingSubNav';
import { element } from 'prop-types';
import RelatedRecipes from '../components/RelatedRecipes';
import NutritionPD from '../components/ProductDetail/NutritionPD';
import CookingInstructions from '../components/ProductDetail/CookingInstructions';
import Tips from '../components/ProductDetail/Tips';
import background from '../images/product-detail-bg.png';
import NutritionBG from '../images/craft-bg.png';

const StyledProductDetail = styled.div`
  .page-container {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const sections = [
  {
    id: 'nutrition',
    type: 'partialWidth',
    background: { NutritionBG },
    title: 'Nutrition',
    content: <NutritionPD />
  },
  {
    id: 'cooking',
    type: 'partialWidth',
    background: { NutritionBG },
    title: 'Cooking Instructions',
    content: <CookingInstructions />
  },
  {
    id: 'tips',
    type: 'partialWidth',
    background: { NutritionBG },
    title: 'Tips',
    content: <Tips />
  },
  {
    id: 'relatedRecipes',
    type: 'fullWidth',
    background: { NutritionBG },
    title: 'Related Recipes',
    content: <RelatedRecipes />
  }
];

class ProductDetail extends Component {
  state = {
    invertCtaBg: false,
    ctaBaseBg: '#3c6bac',
    ctaInvertedBg: '#000000',
    showFloatingProduct: false
  };

  render() {
    return (
      <StyledProductDetail>
        <ProductDetailHero
          title="Elbow Macaroni"
          backgroundImage={background}
        />
        <FloatingSubNav
          navItems={sections.map(section => section.id)}
          ctaBgShouldInvert={elementBg => {
            this.setState({
              invertCtaBg: colorConflict(elementBg, this.state.ctaBaseBg)
            });
          }}
          handleFloatingProduct={element => {
            // when the active element changes - show the product if it's one of these sections
            this.setState({
              showFloatingProduct: ['nutrition', 'cooking', 'tips'].includes(
                element
              )
            });
          }}
        />
        <div className="product-info">
          {sections.map(({ type, background, title, content, id }) => (
            <PdpSection
              key={id}
              type={type}
              style={{ backgroundImage: 'url(' + background + ')' }}
              title={title}
              sectionId={id}
            >
              {content}
            </PdpSection>
          ))}
        </div>
        {/* <FloatingProduct
          inverted={this.state.invertCtaBg}
          ctaBg={
            this.state.invertCtaBg
              ? this.state.invertCtaBg
              : this.state.ctaBaseBg
          }
          show={this.state.showFloatingProduct}
        /> */}
      </StyledProductDetail>
    );
  }
}

export default ProductDetail;
